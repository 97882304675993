import React from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { Row, Container, Col } from 'react-bootstrap/'
import TopHeader from "../../../components/top-header"

import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const VeriToplama = ({intl}) => {
  const title = intl.formatMessage({ id: "modules_content1__2" })
  const data = useStaticQuery(graphql`
    query {
      headerImage: file(relativePath: { eq: "modules/veri.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return <Layout>
    <TopHeader />
    <SEO title={title} />
    <Container>
 
      <div className='image-wrapper'>
        <Img style={{ borderRadius: '1rem' }} alt="retmes veri ve kontrol" fluid={data.headerImage.childImageSharp.fluid} />
        <h2 className='image-wrapper-text'><FormattedMessage id="modules_title1__1" /></h2>
      </div>
      <h1 className='page-title'>{title}</h1>
      <Row className='content-page-row'>
        <Col sm={12}>
          <p className='content-page-p'>
            <FormattedMessage id="modules_vts_desc" />
          </p>
          <br />
          <br />
          <br />
          <br />
        </Col>
      </Row>
      <Row style={{ height: '50vh' }}>
      </Row>
    </Container>
  </Layout>
}

export default injectIntl(VeriToplama)